.date-selector-dd {
    background: #4f4f4f;
        color: white;
        text-align: center;
        border: none;
        padding: 2px;
}

.num-input {
        max-width: 74px;
            background: #4f4f4f;
            color: white;
            text-align: center;
            border-radius: 15px;
            border: none;
            padding: 2px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
}

.option {
    background: #4f4f4f;
}