.bannerIframeOuter {
    align-self: center;
    border: 1px solid #1e1e1e;
    box-shadow: 0 0 16px 4px #1c1c1c7a;
    display: flex;
    margin-bottom: 12px;
    max-height: 73vw;
    position: relative;
    width: 100%;
    flex: 1;
}

.content-edit-btn {
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    right: 56px;
    opacity: 0;
    visibility: hidden;
    transition: visibility .2s, opacity .2s;
}

.bannerIframe {
    width: 100%;
    height: 99%;
}

.bannerDescription {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 18px;
    height: 34%;
    align-items: flex-end;
    grid-gap: 9px;
    cursor: pointer;
    font-size: 27px;
    font-weight: 400;
    text-align: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 1%, rgb(0 0 0 / 80%) 57%, rgba(0, 0, 0, 1) 81%);
    display: flex;
}

.bannerDescription:hover .bannerDescriptionText {
    text-decoration: underline;
}

.ticketOps-btn-container {
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-right: auto;
    margin-left: auto;
    grid-gap: 15px;
    overflow-y: auto;
    width: 100%;
    height: fit-content;
    margin: 15px 0px;
}

.pdf-link-btn {
    background: #33868d;
    padding: 10px;
    font-size: 20px;
    border: solid 4px #3fc2cd;
    border-radius: 10px;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pdf-link-btn:hover {
    cursor: pointer;
}

.action-buttons {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    visibility: hidden;
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
}

.pdf-link-btn:hover .action-buttons {
    visibility: visible;
}

.delete-btn {
    background: #a71930;
    color: white;
}

.action-btn {
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    align-items: center;
    font-size: 18px;
}