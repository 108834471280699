.banner {
    padding: 10px;
    background: #a71930;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.current-user {
    color: #3fc2cd;
}

.banner-header {
    color: #3fc2cd;
    font-size: 30px;
}

.banner-left {
    display: flex;
    flex-direction: row;
    grid-gap: 15px;
    cursor: pointer;
    align-items: center;
}