@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600&display=swap');

body {
  margin: 0px !important;
  /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-family: 'Poppins', sans-serif;
  background-color: #1f1f1f !important;
  overflow: hidden;
  color: #c3c3c3;
}
button {
  font-family: inherit;
}

input {
  font-family: inherit;
}

.App {
  text-align: center;
  background: black;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 50px;
  grid-gap: 12px;
}

.App.Full-Page {
  padding: 20px 40px !important;
  height: calc(100vh - 120px);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-text {
  color: #c3c3c3;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.peek-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.icon-text-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 10px;
}

.teal-icons {
  color: #3fc2cd;
  font-size: 25px;
  text-shadow: 0 0 12px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9f9f9f;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9f9f9f;
  cursor: pointer;
}

.btn {
  transition: box-shadow .2s;
  cursor: pointer;
  font-weight: 400;
  max-width: 250px;
  align-self: center;
  border-radius: 7px;
  grid-gap: 6px;
  padding: 9px 37px;
  border: none;
  box-shadow: 0px 0px 12px #000000b5;
  font-size: 19px;

  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.btn:hover {
  box-shadow: none;
}


.btn1 {
  background: #c13d53;
  color: white;
}

.btn2 {
  background: black;
  color: white;
  border: solid 2px white !important;
}

.btnCancel {
  background: #000;
  border: 2px solid #ffffff30 !important;
}

.btnDelete {
  color: var(--sedonared);
  border: solid 2px var(--sedonared) !important;
  background: black;
}


.btn-slim {
  padding: 9px 15px !important;
}

.btn3 {
  font-size: 15px;
  padding: 3px 16px;
  border: solid 1px gray;
  color: gray;
}

.btn3:hover {
  color: white;
  border: solid 1px white;
}


.button {
  width: 100px;
  padding: 5px 10px;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  min-width: max-content;
}

.title {
  color: white;
  font-size: 35px;
}

.subtitle {
  color: white;
  font-size: 25px;
  font-weight: 100;
}

.title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 15px;
  align-self: center;
}

.half-screen-column {
  display: flex;
  flex-direction: column;
  height: 75vh;
  width: 45%;
  justify-content: space-between;
  max-height: 100%;
}

.app-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  max-width: 2000px;
  max-height: 600px;
}

.module-btn-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.info-display-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.lastHistory {
  display: flex;
  flex-basis: 40%;
}

.module-btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 60%;
}

.view-history-module {
  display: flex;
  flex-basis: 40%;
}

.collapsable-btns {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

.collapsable-btn {
  text-decoration: none;
    color: white;
    background: #a71930;
    filter: drop-shadow(4px 3px 4px #811123);
    padding: 5px;
    border-radius: 5px;
}

.collapsable-btn:hover {
  filter: drop-shadow(4px 8px 4px #811123);
}