.donutchart-legend-label {
    color: white;
}

.donutchart-innertext {
    color: white;
}

.donutchart-legend {
    color: white;
}

g {
    color: white;
}