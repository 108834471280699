.loading-dimTable-title {
    background: #5555558c;
    width: 40%;
    height: 40px;
    border-radius: 5px;
    justify-self: center;
}

.loading-table {
    background: #5555558c;
    height: 400px;
    width: 80vw;
    border-radius: 10px;
}

.animate {
    background: linear-gradient(45deg, black, #151515, #282828, #151515);
    background-size: 400% 400%;
    -webkit-animation: Gradient 8s ease infinite;
    -moz-animation: Gradient 8s ease infinite;
    animation: Gradient 8s ease infinite;
}

.loading-header-params {
    width: 130px;
    border-radius: 5px;
    height: 25px;
}

.loading-data-params {
    width: 130px;
    border-radius: 5px;
    height: 40px;
}