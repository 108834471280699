.module-container {
    background: #1f1f1f65;
    border: solid 4px #1f1f1f;
    border-radius: 20px;
    padding: 30px 40px 40px 40px;
    min-height: max-content;
}

.module-container:hover {
    background: #1f1f1f;
    border-color: #9f9f9f;
}

.module-container-slim {
    background: #1f1f1f65;
    border: solid 4px #1f1f1f;
    border-radius: 10px;
    display: flex;
    flex-basis: 45%;
    padding: 20px 5px !important;
    justify-content: center;
}

.module-container-biParams {
    display: flex;
    padding: 5px !important;
    justify-content: space-around;
}

.module-container-slim:hover {
    background-color: #3fc2cd35;
    border-color: #3fc2cd;
    cursor: pointer;
}

.scrollable-container {
    width: 100%;
    max-height: 80vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding-right: 5%;
}

.dim-dashboard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-top: 20px; */
    overflow-x: hidden;
}

.dashboard-header {
    display: flex;
    font-weight: 600;
    margin-bottom: 20px;
    justify-self: center;
    /* border-bottom: solid 1px darkgray; */
    font-size: 22px;
}


.list-line {
    border-bottom: solid 2px #434343;
    width: 100%;
    padding: 10px 5px;
    display: flex;
    flex-direction: row;
    position: relative;
    cursor: pointer;
}

.quicklink-buttons {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    visibility: hidden;
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
}

.dimTable-header {
    color: #5b5b5b;
}

.list-line:hover {
    background-color: #5f5f5f;
}

.list-line:hover .quicklink-buttons {
    visibility: visible;
}

.list-line:hover .dimTable-errorCount {
    visibility: hidden;
}

.error-btn {
    color: white;
    background: #d33f58;
}

.error-desc {
    display: none;
}

.error-btn:hover .error-desc{
    display: flex;
}

.add-btn {
    color: white;
    background: green;
}

.add-desc {
    display: none;
}

.add-btn:hover .add-desc {
    display: flex;
}

.edit-btn {
    color: white;
    background: blue;
}

.edit-desc {
    display: none;
}

.edit-btn:hover .edit-desc {
    display: flex;
}

.dimTable-name {
    flex-basis: 70%;
    text-align: center;
}

.dimTable-data {
    display: flex;
}

.dimTable-status {
    flex-basis: 40%;
}

.dimTable-errorCount {
    flex-basis: 30%;
    justify-content: end;
}

.error {
    color: #a71930;
}

.healthy {
    color: green;
}

.table-header-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 5px;
    justify-content: space-between;
}

.toggle-row {
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    grid-gap: 5px;
    align-self: flex-end;
}

.clickable-icons {
    cursor: pointer;
    font-size: 30px;
}

.caret {
    display: flex;
    flex-basis: 50%;
    justify-content: center;
}

.caret.left {
    justify-self: flex-start;
}

.caret.right {
    justify-self: flex-end;
}

.dot-highlighted {
    color: #a71930;
}

.header-icon-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.ql-action-btn {
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    align-items: center;
}

.form-fieldgroup {
    display: flex;
    grid-gap: 4px;
    flex-direction: row;
    max-width: 300px;
    align-self: center;
    width: max-content;
    align-items: center;
    justify-content: center;
}

.form-fieldgroup select {
    background: #1f1f1f;
    color: white;
    width: max-content;
    font-size: 15px;
    padding: 5px;
    border-radius: 3px;
}

.title-row-dropdown-info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.loading-line {
    background: #5555558c;
    width: 100%;
    height: 40px;
    border-radius: 5px;
}

.loading-container {
    width: 100%;
    /* min-height: 15vh; */
    display: flex;
    flex-direction: column;
    padding-right: 5%;
    grid-gap: 10px;
}

.loading-line.columnName {
    border-radius: 5px;
    height: 19px !important;
    flex-basis: 40%;
    margin-bottom: 5px;
}

.loading-line.header-line {
    flex-basis: 55%;
    height: 25px !important;
}

.loading-line.loading-dd {
    flex-basis: 80%;
    height: 25px !important;
}

.runHistory-formatting {
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 13px;
}

.startTime {
    flex-basis: 30%;
    display: flex;
}

.interval {
    flex-basis: 45%;
    display: flex;
    text-align: left;
}

.export-name {
    flex-basis: 15%;
    display: flex;
    justify-content: flex-end;
}

.run-success {
    flex-basis: 10%;
    display: flex;
    justify-content: flex-end;
}

.stack-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.subtext {
    font-size: 12px;
}

.enabled {
    color: #3fc3cd;
}

.disabled {
    color: #838383;
}

.twoYears-on {
    background: #a7193055;
    border-color: #a71930;
}

.run-history-status {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.run-history-errors {
    color: white;
    font-size: 10px;
}

.run-history-status.healthy-status {
    background-color: green;
}

.run-history-status.error-status {
    background-color: #a71930;
}

.current-year-display {
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
}

.current-year {
    font-size: 40px;
    font-weight: 300;
    letter-spacing: 3px;
}

.current-year-header {
    font-size: 15px;
    font-weight: 600;
    color: #3fc2cd;
}

.pulling-years-display {
    /* background: #a7193055;
    border: solid 4px #a71930; */
    background: #1f1f1f65;
    border: solid 4px #1f1f1f;
    border-radius: 42px;
    font-size: 25px;
    padding: 4px 16px;
}

.pulling-years-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 10px;
}