.react-tabs__tab {
    padding: 6px 40px !important;
    font-size: 15px;
}

.react-tabs__tab-panel--selected {
    display: flex !important;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.react-tabs__tab--selected {
    color: white !important;
    background-color: #3FC2CD !important;
    border-color: white !important;
}

.react-tabs {
    color: white !important;
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.react-tabs__tab-list {
    display: flex;
    justify-content: flex-start;
    width: auto;
}

.column-list {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    width: 100%;
    align-items: center;
}

.column-labels {
    display: flex;
    justify-content: flex-end;
}

.data-entry {
    display: flex;
    border: solid white 2px;
    background-color: #1f1f1f;
    padding: 8px 16px;
    color: white;
    border-radius: 15px;
    width: -webkit-fill-available;
}

.column-entry-section {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    align-items: flex-start;    
    width: 80%;
    max-width: 500px;
}

.action-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    flex: 1;
}

.action-btn-row {
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    align-items: center;
    justify-content: center;
}

.clone-header-row {
    margin: 10px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    grid-gap: 25px;
    width: 90%;
    justify-content: center;
}

select {
    display: flex;
        border: solid white 2px;
        background-color: #1f1f1f;
        padding: 8px 16px;
        color: white;
        border-radius: 15px;
        width: -webkit-fill-available;
}

.addrow-dd {
    width: 100%;
    color: white;
}

.addrows-input-dd {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-dd-options {
    max-height: 150px;
    display: flex;
    flex-direction: column;
    border: solid white 1px;
    background-color: #1f1f1f;
    padding: 4px;
    color: white;   
    /* border-radius: 15px; */
    width: -webkit-fill-available;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    width: 95%;
    z-index: 99;
}

.dd-options {
    width: 100%;
    text-align: left;
    padding-left: 5px;
}

.dd-options:hover {
    background: lightgray;
    color: black;
    cursor: pointer;
}