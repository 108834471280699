.modal {
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: white;
    background: #101010c7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 500px;
    z-index: 1;
}

.modal.no-click {
    pointer-events: none;
}

.modal-content {
    max-width: 450px;
    background-color: #101010;
    padding: 20px 40px;
    border-radius: 20px;
    border: solid 3px #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 85vh;
    flex: 1;
    overflow: hidden;
}

.modal-content.modal-wide {
    width: 800px;
}

.modal-body.centered {
    align-items: center;
}

.modal-header,
.modal-footer {
    padding: 10px;
}

.modal-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    grid-gap: 20px;
}

.modal-title {
    font-weight: bold;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal-subtitle {
    padding: 5px 0px 10px 0px;
    font-weight:100;
    font-size: 20px;
    border-bottom: solid 1px white;
    margin-bottom: 10px;
}

.modal-body {
    padding: 15px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
}

.modal-body-row {
    padding: 15px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    grid-gap: 125px;
}

.modal-body.modal-body-scrollable {
    overflow: auto;
    flex: 1;
    width: 100%;
}

.check-box {
    display: flex;
    flex-direction: row;
    flex-basis: 10%;
}

.check-description {
    display: flex;
    flex-basis: 90%;
}

.checkbox-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: center;
}

input[type="radio"] {
    background-color: #1f1f1f;
    margin: 0;
    font: inherit;
    accent-color: #a71930;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid red;
    border-radius: 50%;
    transform: translateY(-0.075em);
}

.selection-section {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
}

.selection-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.invalid-list {
    grid-gap: 8px;
}

.subtitle-border {
    font-size: 18px;
    border-bottom: solid 1px white;
    padding-bottom: 8px;
    margin-bottom: 8px;
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.run-history-label {
    font-weight: 200;
    color: #939393;
}

.run-history-value {
    font-size: 25px;
}

.label-info-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: max-content;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
}