.tab-selector {
    background-color: red;
}

.react-tabs__tab {
    padding: 6px 40px !important;
    font-size: 12px;
}

.react-tabs__tab--selected {
    color: white !important;
    background-color: #3FC2CD !important;
    border-color: white !important;
}

.react-tabs {
    color: white !important;
    width: 80%;
    align-self: center;
}

.react-tabs__tab-list {
    display: flex;
    justify-content: center !important;
    font-size: 20px;
    grid-gap: 10px;
}

.missing-key-header {
    background-color: #3FC2CD;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px 0px;
    font-size: 20px;
    font-weight: 400;
}

.missing-key-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px 0px;
    border-bottom: solid 1px #4f4f4f
}

.header-major-minor {
    display: flex;
    flex-basis: 60%;
    justify-content: center;
}

.header-manifest {
    display: flex;
    flex-basis: 40%;
    justify-content: center;
}

.data-major-minor {
    display: flex;
    flex-basis: 60%;
    justify-content: flex-start;
    padding-left: 20px;
}

.data-manifest {
    display: flex;
    flex-basis: 40%;
    justify-content: center;
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    flex: 1;
    overflow: hidden;
}

.missing-key-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    flex: 1;
    overflow: hidden;
}

.tab-description {
    font-size: 15px;
    font-weight: 300;
    margin: 10px 0px 5px 0px;
    width: 80%;
}

.scrollable-container {
    width: 100%;
    /* min-height: 15vh; */
    max-height: 70vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding-right: 5%;
}

.scrollable-x {
    max-width: 85vw;
    overflow-x: auto !important;
    overflow-y: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.search-expand-row {
    display: flex;
    justify-content: space-between;
    margin: 15px 15px;
    align-self: center;
    align-items: baseline;
}

.table-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.scrollable-y {
    min-width: max-content;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
}

.column-list-container {
    width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
}

.ticket-header {
    font-size: 40px;
    color: white;
    width: 100%;
    justify-content: center;
}

.back-btn {
    cursor: pointer;
    align-items: center;
    display: flex;
    grid-gap: 5px;
    align-self: flex-start;
}

.back-btn:hover {
    color: #3FC2CD;
}

.header-dupeData {
    display: flex;
    flex-basis: 25%;
    justify-content: center;
    font-size: 15px;
}

.data-dupeData {
    display: flex;
    flex-basis: 25%;
    justify-content: center;
    font-size: 20px;
}

.dupeData-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.dupeData-header-row {
    background-color: #3FC2CD;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px;
    font-weight: 400;
    grid-gap: 10px;
}

.btn-container {
    background: #3fc2cd35;
    border: solid 4px #3fc2cd;
    border-radius: 10px;
    display: flex;
    flex-basis: 25%;
    padding: 12px 5px !important;
    justify-content: center;
    color: #3fc2cd;
    cursor: pointer;
    align-items: center;
    grid-gap: 5px;
    font-size: 30px;
}

.btn-container:hover {
    font-weight: 600;
}

.btn-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    grid-gap: 40px;
    margin-top: 20px;
}

.header-dimTable {
    display: flex;
    justify-content: flex-start;
    /* width: 130px; */
    padding: 0px 5px;
}

.header-dimTable-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 20px;
    font-size: 15px;
    font-weight: 400;
    background-color: #37929a;
    color: white;
}

.header-dimTable-row.wide-table {
    width: max-content;
}

.data-dimTable {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    word-break: break-all;
    padding: 10px 5px;
}

.data-dimTable-noEdit {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    word-break: break-all;
    padding: 10px 5px;
    color: #c3c3c3;
}

.data-dimTable:hover {
    border: solid 2px white;
    cursor: text;
}

.data-dimTable-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 20px;
    border-bottom: solid 1px #4f4f4f;
    font-size: 15px;
    align-items: center;
    color: #c3c3c3;
}

.data-dimTable-row:hover {
    background: #5b5b5b !important;
}

.data-dimTable-row.to-clone {
    background: #393939;
}

.data-dimTable-row.unselected {
    background: black;
    color: #6a6a6a
}

.data-dimTable-row.wide-data {
    width: max-content;
}

.data-magnets {
    display: flex;
    flex-direction: column;
    background: #3fc2cd44;
    width: 250px;
    border: solid 2px #3fc2cd;
    border-radius: 5px;
    grid-gap: 10px;
    align-items: flex-start;
    padding: 10px;
    justify-content: space-around ;
}

.info-display {
    text-wrap: wrap;
    text-align: left;
    flex-wrap: wrap;
    display: flex;
    word-break: break-all;
}

.info-display.headerText {
    color: #3fc2cd
}

.magnet-container {
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-right: auto;
    margin-left: auto;
    grid-gap: 15px;
    overflow-y: auto;
}

.loading-state {
    height: 100%;

}

.table-preview-magnet-row {
    display: flex;
    flex-direction: row;
    /* margin-right: auto; */
    /* margin-left: auto; */
    /* grid-gap: 15px; */
    justify-content: space-around;
}

.test-bg {
    width: 100wh;
    height: 90vh;
    background: linear-gradient(45deg, black, #151515, #282828, #151515);
    background-size: 400% 400%;
    -webkit-animation: Gradient 8s ease infinite;
    -moz-animation: Gradient 8s ease infinite;
    animation: Gradient 8s ease infinite;
}

.user-name {
    width: 135px;
    height: 30px;
    border-radius: 5px;
    background: linear-gradient(45deg, #a71930, #d36073, #a71930, #550e1a);
    background-size: 400% 400%;
    -webkit-animation: Gradient 4s ease infinite;
    -moz-animation: Gradient 4s ease infinite;
    animation: Gradient 4s ease infinite;
}

.landing-page-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    align-self: center;
}

.paused {
    -webkit-animation-play-state: paused;
    /* Safari 4.0 - 8.0 */
    animation-play-state: paused;
}

.nav-icons {
    color: white;
}

.nav-icons:hover {
    color: #3fc2cd;
    cursor: pointer;
}

.missing-data {
    padding-right: 20%;
    margin-bottom: 10px;
    align-self: flex-end;
    cursor: pointer;
}

.missing-data:hover {
    color: #a71930;
}

.upload-label {
    background-color: #a71930;
    padding: 5px 10px;
    border: solid 2px white;
    border-radius: 15px;
    font-size: 15px;
}

.upload-label.teal-btn {
    background-color: #a71930;
    color: white;
}

.add-rows-btn {
    background-color: #a71930;
    padding: 5px 10px;
    border: solid 2px white;
    border-radius: 15px;
    font-size: 15px;
    width: max-content;
    cursor: pointer;
}

.upload-import-row {
    display: flex;
    flex-direction: row;
    grid-gap: 15px;
    align-items: center;
    margin: 8px 0px;
}

.action-row {
    display: flex;
    flex-direction: row;
    grid-gap: 40px;
    align-self: flex-start;
}

.data-input {
    width: 120px;
}

.data-input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 3px;
}

.edit-input {
    max-width: 120px;
}

.disabled-input {
    background: none;
    border: none;
    color: #c3c3c3 !important;
    font-size: 15px;
}

@-webkit-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}


.item {
    align-items: center;
    color: #FFF;
    cursor: pointer;
    display: flex;
    font-size: 10px;
    height: 30px;
    justify-content: center;
    width: 30px;
}

.disabled-page {
    color: #808e9b;
}

.active {
    border: solid 2px #3fc2cd;
    color: white;
    background: #3fc2cd;
    border-radius: 25px;
}

.break-me {}

.next {
    font-size: 4px;
}

.pagination {
    align-items: center;
    /* background-color: #37929a; */
    display: flex;
    flex-direction: row;
    /* height: 30px; */
    justify-content: center;
    list-style-type: none;
    position: relative;
    width: max-content;
}

.pagination-page {
    font-weight: 700;
}

.previous {
    font-size: 4px;
}

.header-content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title-search-container {
    width: 90%;
    align-self: center;
}

.search-bar {
    display: flex;
    border: solid white 2px;
    background-color: #1f1f1f;
    padding: 8px 16px;
    color: white;
    border-radius: 15px;
}

.clone-input-dropdown {
    width: 132px !important;
}

.relative {
    position: absolute;
    width: 160px !important;
}

.no-data {
    margin-top: 80px;
    font-size: 20px;
}

.table-top-left {
    display: flex;
    flex-direction: row;
    grid-gap: 15px;
    align-items: center;
}

.uncheck-btn {
    background-color: #a71930;
    padding: 5px 10px;
    border: solid 2px white;
    border-radius: 15px;
    font-size: 15px;
    cursor: pointer;
    color: white;
}